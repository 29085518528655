import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import drug from "../images/drugplaceholderImg.png"
import { Tab } from "react-tabs"
import { Link } from "gatsby"
import algoliasearch from "algoliasearch/lite"
import SearchBox from "./../components/SearchBox"
import { BsWhatsapp } from "react-icons/bs"
import { BsShareFill } from "react-icons/bs"
import {
  InstantSearch,
  connectHits,
  connectSearchBox,
  connectStateResults,
} from "react-instantsearch-dom"
import { Hits } from "../components/DisplayHits"
import { globalHistory } from "@reach/router"
import Buttn from "../components/Button"
import Seo from "../components/seo"
import api from "../utils/api"

import { Card, Alert, Row, Col, Form, Button } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import LoadingOverlay from "react-loading-overlay"
import OrderModal from "../components/OrderModal"
import Modal from "../components/home/modal"
import { navigate } from "gatsby"
import axios from 'axios';


const product = {
  productName: "Panadol",
  productImg: [drug],
  GenericName: "Paracetamol",
  fomulation: "Tablets",
  strength: "1000mg",
  pack: "100 pack",
  manufacturer: "GSK",
  distributor: "Surgipharm",
  handler: "Henry Kimani",
  country: "Kenya",
  condition:
    "Paracetamol, also known as acetaminophen, is a medication used to treat fever and mild to moderate pain. At a standard dose, paracetamol only slightly decreases body temperature; it is inferior to ibuprofen in that respect, and the benefits of its use for fever are unclear",
}

if (typeof window !== `undefined`) {
  window.dataLayer = window.dataLayer || []
}

const SimilarProduct = ({ item, openOrderModal }) => {
  const [distributor, setDistributor] = useState(item.distributor || {})
  const [wholesalers] = useState(item.wholesalers || [])

  const openModal = e => {
    e.preventDefault()
    openOrderModal({
      distributor,
      wholesalers,
      drugId: item.id,
      tab: "order",
    })
  }

  return (
    <div>
      <PCard>
        <div>
          <PLink
            to={`/product/${item.productTradeName
              .replace(/ /g, "+")
              .toLowerCase()}`}
          >
            <ProductInfo2>
              <div>
                <img
                  css={`
                    width: 3rem;
                    height: 3rem;
                  `}
                  src={drug}
                  alt={item.productTradeName}
                  style={{ margin: "auto" }}
                />
                <p style={{ marginTop: "0.5rem", fontWeight: "bold" }}>
                  {item.productTradeName.toUpperCase()}
                </p>
              </div>
              <div>
                <p style={{ marginBottom: "0.5rem" }}>{item.innOfApi}</p>
                <p style={{ marginBottom: "0.5rem" }}>
                  {item.apiStrengthPerDosage}
                </p>
                <p style={{ marginBottom: "0.5rem" }}>{item.dosageFormName}</p>
                {/* <p style={{ marginBottom: "0.5rem" }}>
                  {distributor.distributor.name}
                </p> */}
              </div>
            </ProductInfo2>
          </PLink>
        </div>
        <ButtonDiv
          style={{
            display: Object.entries(distributor).length > 0 ? "flex" : "none",
          }}
        >
          <ButtonGreenCard onClick={() => navigate(`/product/${item.productTradeName
            .replace(/ /g, "+")
            .toLowerCase()}`)}>Details</ButtonGreenCard>
          <ButtonGreenCard >0792 640 973</ButtonGreenCard>

          {/* <ButtonsCard>
            {Buttn("Show Contact", distributor.distributor.handler.phoneNumber)}
          </ButtonsCard> */}
        </ButtonDiv>
      </PCard>
    </div>
  )
}

const SimilarProducts = ({ products, openOrderModal }) => {
  const productArray = products.map(item => item.node)

  return productArray.map((item, index) => (
    <SimilarProduct key={index} item={item} openOrderModal={openOrderModal} />
  ))
}

function RequestCallbackForm({ user, distributor, drugId }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [callBackName, setCallBackName] = useState(user?.fullName)
  const [callBackNumber, setCallBackNumber] = useState("")

  const requestCallback = async e => {
    e.preventDefault()
    setSuccess("")
    setError("")

    const data = {
      userId: user.id,
      drugId,
      distributor,
      name: callBackName,
      number: callBackNumber,
    }

    try {
      if (!callBackName || !callBackNumber) {
        const message = "All fields are required"
        return setError(message)
      }
      if (callBackName.length < 3) {
        const message = "Name has to be more than two characters"
        return setError(message)
      }
      if (callBackNumber.length != 10) {
        const message = "Invalid phone number"
        return setError(message)
      }
      setError("")
      setLoading(true)
      await api().post(`/users/callback`, { ...data })
      setSuccess("Success, the distributor will call you back")
      window.dataLayer.push({ event: 'callback_request', callback_data: data });

      setTimeout(() => {
        setSuccess("")
      }, 5000)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          <Form.Label>
            <h5 className="text-muted pt-2">Request med rep to call back</h5>
          </Form.Label>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Control
              className="mb-2"
              type="text"
              value={callBackName}
              onChange={e => setCallBackName(e.target.value)}
            />
            <Form.Control
              type="text"
              placeholder="Your phone number"
              value={callBackNumber}
              onChange={e => setCallBackNumber(e.target.value)}
            />

            <RequestCallBackBtn onClick={requestCallback}>
              <div onClick={e => requestCallback}>Send</div>
            </RequestCallBackBtn>
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

function SendMessageForm({ distributor, user, drugId }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState(user?.email)

  const sendMessage = async e => {
    e.preventDefault()
    setSuccess("")
    setError("")

    const data = {
      userId: user.id,
      distributor,
      message,
      drugId,
    }

    try {
      if (!message) {
        const errorMessage = "Message is required"
        return setError(errorMessage)
      }
      if (message.length < 5) {
        const errorMessage = "Message has to be at least five characters"
        return setError(errorMessage)
      }

      setError("")
      setLoading(true)
      await api().post(`/users/message`, { ...data })
      setSuccess("Sent message to distributor")
      window.dataLayer.push({ event: 'send_message', message_data: data });

      setMessage("")
      setTimeout(() => {
        setSuccess("")
      }, 5000)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          {error ? (
            <Alert className="mt-1" variant="danger">
              {error}
            </Alert>
          ) : (
            ""
          )}
          {success ? (
            <Alert className="mt-1" variant="success">
              {success}
            </Alert>
          ) : (
            ""
          )}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Control
              className="my-2"
              type="text"
              placeholder="Your email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Form.Control
              as="textarea"
              placeholder="Write your message here"
              rows={3}
              value={message}
              onChange={e => setMessage(e.target.value)}
            />

            <RequestCallBackBtn onClick={sendMessage}>
              <div>Send Message</div>
            </RequestCallBackBtn>
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

function SampleProductForm({ distributor, user, drugId }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const sampleProduct = async e => {
    e.preventDefault()
    setSuccess("")
    setError("")

    const data = {
      userId: user.id,
      distributor,
      drugId,
    }

    if (!user?.license && !user?.placeOfWork?.license) {
      navigate("/profile")
    } else {
      try {
        setError("")
        setLoading(true)
        await api().post(`/users/sample`, { ...data })
        setSuccess("The distributor will get back to you.")
        window.dataLayer.push({ event: 'sample_request', sample_data: data });

        setTimeout(() => {
          setSuccess("")
        }, 5000)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        const message = error.response.data.message
        setError(message)
      }
    }
  }

  return (
    <div>
      {/* <Form className="mx-2">
        <Form.Group>
          {error ? (
            <Alert className="mt-1" variant="danger">
              {error}
            </Alert>
          ) : (
            ""
          )}
          {success ? (
            <Alert className="mt-1" variant="success">
              {success}
            </Alert>
          ) : (
            ""
          )}
          <LoadingOverlay active={loading} spinner text="">
            <RequestCallBackBtn onClick={sampleProduct}>
              <div>Sample Product</div>
            </RequestCallBackBtn>
          </LoadingOverlay>
        </Form.Group>
      </Form> */}
    </div>
  )
}

const Product = ({ location, pageContext, data }) => {
  const item = pageContext.product
  // console.log("item", item.productTradeName)

  const similarProducts = data.similarProducts?.edges

  const distributor = item.distributor || {}
  const wholesalers = item.wholesalers || []

  const isBrowser = typeof window !== "undefined"
  const user = isBrowser ? JSON.parse(window.localStorage.getItem("user")) : {}
  //console.log("user", user)

  const [isOrderModalOpen, setIsOrderOpen] = useState(false)
  const [showInfo, toggleShowInfo] = useState(false)


  {/* const handleAddToCart = async () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      alert('You need to be logged in to add items to the cart. Please log in.');
      navigate('/login');
      return;
    }
    console.log('userId:', userId);
  
    if (!localStorage.getItem('userId')) {
      localStorage.setItem('userId', userId);
    }
  
    try {
      const response = await axios.post(`${process.env.GATSBY_API_URL}/cart/add`, {
        userId,
        drugId: item.id,
        quantity: 1,
      });
      console.log('Item added to cart:', response.data);
  
      const itemExists = response.data.items.find(i => i.drug.toString() === item.id);
  
      if (itemExists) {
        localStorage.setItem('cartCount', response.data.items.length);
      } else {
        const currentCartCount = parseInt(localStorage.getItem('cartCount'), 10) || 0;
        localStorage.setItem('cartCount', currentCartCount + 1);
      }
  
      const updatedCart = await axios.get(`${process.env.GATSBY_API_URL}/cart/${userId}`);
  
      window.dispatchEvent(new Event('cartUpdated'));
  
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  }; */}
  
  
  
  


  if (typeof window !== `undefined`) {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [
          {
            item_id: item.id,
            item_name: item.productTradeName,
            affiliation: item.distributor.distributor.name,
            //coupon: "SUMMER_FUN",
            currency: "KES",
            discount: item.distributor.price.discount,
            index: 0,
            //item_brand: "Google",
            item_category: item.category,
            item_category2: item.innOfApi,
            // item_category3: "Shirts",
            // item_category4: "Crew",
            // item_category5: "Short sleeve",
            item_list_id: "related_products",
            item_list_name: "Related Products",
            //item_variant: "green",
            //location_id: "L_12345",
            price: item.distributor.price.current,
            quantity: 1
          }
        ]
      }
    });

    //console.log("datalayer ", window.dataLayer)
  }


  const descriptionCard = useRef(null)

  const [isMobile, setIsMobile] = useState(
    isBrowser && window.innerWidth <= 768 ? true : false
  )

  const mediaQuery = "(max-width: 768px)"

  if (isBrowser) {
    let mediaQueryList = window.matchMedia(mediaQuery)
    mediaQueryList.addEventListener("change", event => {
      if (event.matches) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const toggleInfo = e => {
    e.preventDefault()
    if (!showInfo) {
      descriptionCard.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      })
    }

    toggleShowInfo(!showInfo)
  }

  React.useEffect(() => {
    if (isMobile) {
      toggleShowInfo(false)
    } else {
      toggleShowInfo(false)
    }
  }, [isMobile])

  const [orderOptions, setOrderOptions] = useState({})
  const [isOpenPlaceOfWork, setIsOpenPlaceOfWork] = useState(true)

  /* openOrderModal({
    distributor,
    wholesalers,
    drugId: item.id,
    tab: "order",
  }) */

  const openOrderModal = options => {
    setOrderOptions(options)
    if (!user?.placeOfWork?.license) {
      navigate("/profile")
    } else {
      setIsOrderOpen(true)
    }
  }
  const closeOrderModal = () => setIsOrderOpen(false)

  const [searchedState, setsearchedState] = useState(
    location.pathname.split("/").slice(2).toString().replace(/\+/g, " ")
  )

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_ADMIN_KEY
  )
  const CustomHits = connectHits(Hits)
  const CustomSearchBox = connectSearchBox(SearchBox)

  let urlQuery = globalHistory.location.search
    ? globalHistory.location.search.replace("?query=", "")
    : globalHistory.location.pathname
      .split("/")
      .slice(2)
      .toString()
      .replace(/\+/g, " ")

  const getDetails = (data, searchstate) => {
    const data2 = data.filter(
      item => item.node.productTradeName === searchstate
    )

    const ing = data2[0].node.innOfApi

    const altData = data.filter(
      item => item.node.innOfApi.toLowerCase() === ing.toLowerCase()
    )

    return altData.filter(item => item.node.productTradeName !== searchstate)
  }

  const onSearchStateChange = state => {
    if (state.RefinementList?.productTradeName) {
      setsearchedState(state.RefinementList.productTradeName)
    }
  }

  const Results = connectStateResults(({ searchState }) =>
    searchState && searchState.query ? (
      <div>
        <CustomHits />
      </div>
    ) : (
      <div></div>
    )
  )

  // const keyword = urlQuery
  //   ? urlQuery + " price in Kenya - Patameds"
  //   : "medication price in Kenya - Patameds"
  // const keyword2 = urlQuery
  //   ? urlQuery + " in Kenya - price, brand names, uses, "
  //   : "medication in Kenya - price, brand names, uses, "
  // const keyword3 = urlQuery
  //   ? urlQuery + " price in Kenya, "
  //   : "medication price in Kenya, "
  // const keyword4 = urlQuery || "medication"
  // const keyword5 = urlQuery
  //   ? urlQuery +
  //   " tanzania,somalia,uganda,rwanda,zambia,congo,ethiopia,burundi,nigeria,ghana,egypt,senegal,botswana,togo,south africa,malawi, congo - kinshasa,cameroon,zimbabwe,congo - brazzaville,benin,angola,djibouti,mali,namibia, "
  //   : "medication tanzania,somalia,uganda,rwanda,zambia,congo,ethiopia,burundi,nigeria,ghana,egypt,senegal,botswana,togo,south africa,malawi, congo - kinshasa,cameroon,zimbabwe,congo - brazzaville,benin,angola,djibouti,mali,namibia, "
  // const keyword_desc = urlQuery
  //   ? urlQuery +
  //   " brand e-detailing. Generic name, formulation, strength, manufacturer, prices. Request a quote, place an order, and compare prices from suppliers"
  //   : "medication brands in Kenya with their distributors. Discover, Negotiate, Order, Procure, Pay"
  // const siteurl = "https://www.patameds.com/product/" + urlQuery.replace(/ /g, "+").toLowerCase()

  const keyword = item?.productTradeName + " price in Kenya - Patameds"
  const keyword2 = item?.productTradeName + " in Kenya - price, brand names, uses, "
  const keyword3 = item?.productTradeName + " price in Kenya, "
  const keyword4 = item?.productTradeName || "medication"
  const keyword5 = item?.productTradeName + " tanzania,somalia,uganda,rwanda,zambia,congo,ethiopia,burundi,nigeria,ghana,egypt,senegal,botswana,togo,south africa,malawi, congo - kinshasa,cameroon,zimbabwe,congo - brazzaville,benin,angola,djibouti,mali,namibia, "
  const keyword_desc = item?.productTradeName + " brand e-detailing. Generic name, formulation, strength, manufacturer, prices. Request a quote, place an order, and compare prices from suppliers"
  const siteurl = "https://www.patameds.com/product/" + item?.productTradeName?.replace(/ /g, "+").toLowerCase()

  return (
    <Layout>
      <Modal />
      {isOrderModalOpen && (
        <OrderModal
          distributor={orderOptions?.distributor}
          wholesalers={orderOptions?.wholesalers}
          drugId={orderOptions?.drugId}
          item_data={orderOptions?.item_data}
          tab={orderOptions?.tab}
          open={isOrderModalOpen}
          close={closeOrderModal}
          item={item}
        />
      )}

      <Seo
        title={keyword}
        description={keyword_desc}
        keywords={keyword2 + keyword3 + keyword5 + keyword4}
        img="https://www.patameds.com/static/logo-da9b52b652992ee5cb44de5673062d5e.svg"
        siteUrl={siteurl}
        canonical={siteurl}
      ></Seo>
      <Container>
        <InstantSearch
          searchClient={searchClient}
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
          onSearchStateChange={onSearchStateChange}
        //searchState={{ searchedState }}
        >
          {/* <SearchHit>
            <CustomSearchBox defaultRefinement={urlQuery} />
            <Results />
          </SearchHit> */}
          <ProductDetails>
            {
              <ProductScreenshot>
                <TabsStyle>
                  {product.productImg.map((item, index) => (
                    <TabPanelStyle key={index}>
                      <Tab>
                        <img src={item} alt={item.productName} />
                      </Tab>
                    </TabPanelStyle>
                  ))}
                  <Description>
                    <ProductInformation>
                      <ProductInfo1>
                        <div >
                          Brand Name:{" "}
                          <span
                            css={`
                              color: black;
                              font-weight: bold;
                            `}
                          >
                            {item.productTradeName.toUpperCase()}
                          </span>
                        </div>
                        <div>
                          Strength:{" "}
                          <span
                            css={`
                              color: black;
                              font-weight: bold;
                            `}
                          >
                            {item.apiStrengthPerDosage.toLowerCase()}
                          </span>
                        </div>
                        {/* <div>
                          Generic Name:{" "}
                          <Link
                            to={`/search/${item.innOfApi
                              .replace(/ /g, "+")
                              .toLowerCase()}/?query=${item.innOfApi
                                .replace(/ /g, "+")
                                .toLowerCase()}`}
                            style={{ textDecoration: "underline" }}
                          >
                            {" "}
                            {item.innOfApi}
                          </Link>
                        </div> */}
                      </ProductInfo1>
                      <ProductInfo1>
                        <div>
                          Formulation:{" "}
                          <span
                            css={`
                              color: black;
                              font-weight: bold;
                            `}
                          >
                            {item.dosageFormName.toLowerCase()}
                          </span>
                        </div>
                        <div>
                          Generic Name:{" "}
                          <Link
                            to={`/search/${item.innOfApi
                              .replace(/ /g, "+")
                              .toLowerCase()}/?query=${item.innOfApi
                                .replace(/ /g, "+")
                                .toLowerCase()}`}
                            style={{ textDecoration: "underline", fontWeight: "bold" }}
                          >
                            {" "}
                            {item.innOfApi}
                          </Link>
                        </div>
                        {/* <div>
                          Manufacturer:{" "}
                          <span
                            css={`
                              color: blue;
                            `}
                          >
                            {item.mahCompanyName.toLowerCase()}
                          </span>
                        </div>
                        <div>
                          Distributor:{" "}
                          <span
                            css={`
                              color: blue;
                            `}
                          >
                            {distributor.distributor.name.toUpperCase()}
                          </span>
                        </div> */}
                      </ProductInfo1>
                    </ProductInformation>
                  </Description>
                  {!showInfo && (
                    <div className="text-center my-2">
                      <Button onClick={toggleInfo}>Show More Info</Button>
                    </div>
                  )}
                </TabsStyle>

                <OwnerDetails
                  style={{
                    display:
                      Object.keys(distributor).length !== 0 ? "block" : "none",
                  }}
                >
                  <OwnerInfo>
                    <OwnerLogo>
                      {item.productTradeName.toUpperCase()}
                    </OwnerLogo>
                    {user?.admin &&
                      <OwnerDistributor>
                        <Link
                          to={`/distributor/${distributor.distributor.name
                            .replace(/ /g, "+")
                            .toLowerCase()}`}
                        >
                          {distributor.distributor.name}
                        </Link>
                      </OwnerDistributor>
                    }
                    <OwnerHandler>Dr. {`${distributor.distributor.handler.firstName} ${distributor.distributor.handler.lastName}`}</OwnerHandler>
                  </OwnerInfo>
                  <ButtonsOwner>
                  {/* <ButtonOwner
                      className="mb-3"
                      onClick={handleAddToCart}
                    >
                      Add To Cart
                    </ButtonOwner> */}
                    <ButtonOwner
                      className="mb-3"
                      onClick={() => {
                        openOrderModal({
                          distributor,
                          wholesalers,
                          drugId: item.id,
                          tab: "quote",
                          item_data: item
                        })
                      }}
                    >
                      Buy
                    </ButtonOwner>
                    <a href={`https://wa.me/+254792640973?text=Hello Patameds, how much is ${item.productTradeName.toLowerCase()}?&source=productpage`} target="_blank">
                      <ButtonOwner
                        className="mb-3"
                        css={`
                        padding: 1rem 1rem;
                        background: #4cbb17;
                      `}
                      >
                        <BsWhatsapp style={{ display: "inline" }} /> Chat on WhatsApp
                      </ButtonOwner>
                    </a>
                    <ButtonOwner
                      css={`
                        padding: 0.75rem 1rem;
                      `}
                    >
                      {Buttn(
                        "Show Contact",
                        distributor.distributor.handler.phoneNumber
                      )}
                    </ButtonOwner>

                    <ButtonOwner
                      css={`
                      padding: 1rem 1rem;
                      margin-top: 2rem;
                    `}
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        alert("Link Copied to Clipboard!");

                      }}
                    >
                      <BsShareFill style={{ display: "inline", marginRight: "5px" }} />Share Link
                    </ButtonOwner>

                    <br />
                  </ButtonsOwner>
                  <CallBackDetails>
                    <SendMessageForm
                      distributor={distributor.distributor}
                      user={user}
                      drugId={item.id}
                    />
                  </CallBackDetails>
                  <CallBackDetails>
                    <RequestCallbackForm
                      user={user}
                      distributor={distributor.distributor}
                      drugId={item.id}
                    />
                  </CallBackDetails>
                  <CallBackDetails>
                    <SampleProductForm
                      user={user}
                      distributor={distributor.distributor}
                      drugId={item.id}
                    />
                  </CallBackDetails>
                </OwnerDetails>
              </ProductScreenshot>
            }
            <Row
              ref={descriptionCard}
              css={`
                width: 90%;
                margin: auto;
              `}
            >
              {
                <Col
                  css={`
                    display: ${showInfo ? "block" : "none"};
                  `}
                >
                  <Card md={{ span: 6, offset: 3 }} className="my-4">
                    <Card.Body className="text-right">
                      <Button
                        onClick={toggleInfo}
                        style={{
                          backgroundColor: "grey",
                          color: "white",
                          border: "grey",
                        }}
                      >
                        Hide
                      </Button>
                      <div className="text-center">
                        <Card.Title className="text-primary">
                          <h2>{item.productTradeName}</h2>
                        </Card.Title>
                        <hr />
                        <Card.Text>
                          <Row>
                            <Col>
                              <p>
                                Generic name:
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.innOfApi}
                                </span>
                              </p>
                              <p>
                                Api Strength per Dosage:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.apiStrengthPerDosage}
                                </span>
                              </p>
                              <p>
                                Dosage Form Name:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.dosageFormName}
                                </span>
                              </p>
                              <p>
                                Routes Name:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.routesName}
                                </span>
                              </p>
                              {/* <p>
                                Shelf Life Name:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.shelfLifeName}
                                </span>
                              </p> */}
                            </Col>
                            <Col>
                              <p>
                                Retention Year:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.retentionYear}
                                </span>
                              </p>
                              {/* <p>
                                Local Technical Representative:
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {distributor.distributor.name}
                                </span>
                              </p> */}
                              <p>
                                Retention No:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.retentionNo}
                                </span>
                              </p>
                              <p>
                                Atc Code Name:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.atcCodeName}
                                </span>
                              </p>
                              <p>
                                Pharmacotherapeutical Grade:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.pharmacotherapeuticGroup}
                                </span>
                              </p>
                              {/* <p>
                                Product Visual Desc:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.productVisualDesc}
                                </span>
                              </p> */}
                            </Col>
                            {/* <Col>
                              <p>
                                Mah Company Name:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.mahCompanyName}
                                </span>
                              </p>
                              <p>
                                Mah Company Address:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.mahCompanyAddress}
                                </span>
                              </p>
                              <p>
                                Mah Company Email:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.mahCompanyEmail}
                                </span>
                              </p>
                              <p>
                                Site Name:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.siteName}
                                </span>
                              </p>
                              <p>
                                Site Address:{" "}
                                <span
                                  css={`
                                    color: #3255e1;
                                  `}
                                >
                                  {" "}
                                  {item.siteAddress}
                                </span>
                              </p>
                            </Col> */}
                          </Row>
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              }
              <script type="application/ld+json">
                {`{
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "${keyword}",
                        "description": "${keyword_desc}",
                        "image": "https://www.patameds.com/static/logo-da9b52b652992ee5cb44de5673062d5e.svg",
                        "aggregateRating": {
                          "@type": "aggregateRating",
                          "ratingValue": "4",
                          "reviewCount": "1545"
                       }
                      }`}
              </script>
            </Row>

            {similarProducts.length > 0 && (
              <Products>
                <ProductHeader>Brands containing
                  {" "}{item.innOfApi.charAt(0).toUpperCase() + item.innOfApi.slice(1).toLowerCase()}
                </ProductHeader>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                  <Link
                    to={`/search/${item.innOfApi
                      .replace(/ /g, "+")
                      .toLowerCase()}/?query=${item.innOfApi
                        .replace(/ /g, "+")
                        .toLowerCase()}`}
                    style={{ borderRadius: "10px", background: 'green', padding: "5px", color: "white" }}
                  >
                    {" "}
                    See All
                  </Link>
                </div>
                <ProductCards>
                  <SimilarProducts
                    products={similarProducts}
                    openOrderModal={openOrderModal}
                  />
                </ProductCards>
              </Products>
            )}
          </ProductDetails>
        </InstantSearch>
      </Container>
    </Layout>
  )
}

export default Product

export const query = graphql`
  query($productTradeName: String!, $innOfApi: String!) {
    similarProducts: allDrugs(
      filter: {
        innOfApi: { regex: $innOfApi }
        productTradeName: { ne: $productTradeName }
      }
    ) {
      edges {
        node {
          id
          apiStrengthPerDosage
          atcCodeName
          dosageFormName
          innOfApi
          mahCompanyAddress
          mahCompanyEmail
          mahCompanyName
          pharmacotherapeuticGroup
          productTradeName
          productVisualDesc
          retentionNo
          retentionYear
          routesName
          shelfLifeName
          siteAddress
          siteName
          type
          category
          distributor {
            distributor {
              id
              name
              handler {
                email
                firstName
                lastName
                phoneNumber
              }
            }
            price {
              previous
              current
              vat
              discount
            }
            packSize
            minQuantity
            showPrice
          }
          wholesalers {
            wholesaler {
              id
              name
              locations
              handler {
                email
                firstName
                lastName
                phoneNumber
              }
            }
            price {
              previous
              current
              vat
              discount
            }
            minQuantity
            packSize
            showPrice
            showWholesaler
          }
        }
      }
    }
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e4e4e4;
`
const ProductDetails = styled.div`
  margin-top: 0px;
`
const ProductScreenshot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  li {
    list-style: none;
  }

  @media screen and (min-width: 900px) {
    flex-direction: row;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`
const TabsStyle = styled.div`
  height: 100%;
  background: #fff;
  margin: 0rem 1rem;
  margin-bottom: 1rem;

  @media screen and (min-width: 900px) {
    width: 60%;
  }
`
const TabPanelStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }
`
const OwnerDetails = styled.div`
  height: 100%;
  background: #fff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media screen and (min-width: 700px) {
    width: 50%;
  }

  @media screen and (min-width: 900px) {
    width: 35%;
  }

  @media screen and (min-width: 1100px) {
    width: 30%;
  }
`
const OwnerDistributor = styled.h5`
  text-align: center;
  width: 80%;
  margin-top: 1rem;
`
const OwnerHandler = styled.p`
  border: 2px solid #03989e;
  padding: 1rem 2rem;
  margin: 1rem 2rem;
  border-radius: 1rem;
  text-align: center;
`
const OwnerInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const CallBackDetails = styled.div`
  border-top: 10px solid #e4e4e4;
`
const OwnerLogo = styled.h1`
  margin: 1rem;
  border-radius: 10px;
  background: #03989e;
  //height: 100px;
  //width: 100%;
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const Description = styled.div`
  height: 100%;
  border-top: 5px solid #e4e4e4;
`
const ProductInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-bottom: 2px solid #e4e4e4;
  line-height: 2rem;
  height: 100%;
  text-align: center;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`
const ProductInfo1 = styled.div`
  display: flex;
  flex-direction: column;
`
const ProductInfo2 = styled.div`
  display: flex;
  text-align: center;
  margin: 0.1rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction:column;
  color: #000;

  @media screen and (min-width: 400px) {
    justify-content: space-between;
    flex-direction:row;
    width: 100%;

    div {
      width: 50%;
      // flex-wrap: wrap;
      // justify-content: center;
    }
  }
`
const Products = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e4e4e4;
  width: 100%;
`

const ProductHeader = styled.h1`
  text-align: center;
  font-size: clamp(1.3rem, 3vw, 2rem);
  margin-top: 1rem;
`

const ProductCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0rem 0.5rem;
  //align-items: flex-start;
  color: #000;

  @media screen and (min-width: 700px) {
    // align-items: center;
    padding: 0rem 5rem;
  }
`
const PCard = styled.div`
  width: 80%;
  border: 3px solid #03989e;
  border-radius: 10px;
  height: 100%;
  background: white;
  margin: 0.5rem 0rem;
  padding: 0.5rem 0.5rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 600px) {
    width: 100%;
  }
  @media screen and (min-width: 1100px) {
    width: 70%;
  }
`

const PLink = styled(Link)`
  :hover {
    text-decoration: none;
  }

  // img {
  //   width: 70%;
  //   height: 70%;
  // }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: space-around;
  }
`

const ButtonsOwner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`
const RequestCallBackBtn = styled.div`
  background: #03989e;
  padding: 1rem 4rem;
  margin-top: 1rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
`

const ButtonOwner = styled.div`
  background: #03989e;
  padding: 1rem 2rem;
  margin-top: 1rem;
  border-radius: 3rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
`

const SearchHit = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: sticky;
  top: 60px;
`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const ButtonsCard = styled.div`
  width: 50%;
  height: 35px;
  margin-left: 1px;
`
const ButtonGreenCard = styled.div`
  background: #03989e;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  width: 50%;
  height: 35px;
  padding-top: 0.1rem;
  cursor: pointer;
  margin: 0.25rem;
`
